import Vue from 'vue'
import App from './App.vue'
import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'lib-flexible'


Vue.use(ElementUI)
Vue.config.productionTip = false
axios.defaults.baseURL = 'https://cards.alfred-iot.com/api/v1'
Vue.prototype.$http = axios
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    NProgress.start()
    if (sessionStorage.getItem('token')) {
      config.headers.Authorization =
        'Bearer ' + window.sessionStorage.getItem('token')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    NProgress.done()
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
